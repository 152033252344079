.App {
  text-align: center;
  
}
img {
  width: 100%;
}

body{
  background-image: url("https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_1280.jpg");
}